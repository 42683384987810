import React from 'react';
import {styled, Box, LinearProgress} from '@mui/material'
import {ReactComponent as EvadataLogoLoading} from 'src/images/EvadataLogoLoading.svg'


const LoadingWrapper = styled(Box)(
  () => `
    background-color:rgba(255, 255, 255, 0.8);
    z-index: 9996;
    position: fixed; 
    width: 100VW;
    height: 100VH;
    top: 0px;
    left: 0px;

    .loading-inner-wrapper {
      width: 200px;
      margin: 45vh auto ;
    }
    
    .MuiLinearProgress-root {
        border-radius: 0px;
        height: 4px;
        margin-top: 35px;
    }
  `
);

const EvadataLoading = () => {

  return (
    <LoadingWrapper>
      <div className="loading-inner-wrapper">
      <EvadataLogoLoading />
      <LinearProgress color="primary"  className="linear" />
      </div>
    </LoadingWrapper>
  );
};

export default EvadataLoading;
