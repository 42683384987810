import React from 'react';
import { Grid, styled, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import LoadError from 'src/components/LoadError';
import CustomToolbar from './CustomToolbar';


const GridWrapper = styled(Grid)(
  ({ theme }) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-select, svg.MuiSvgIcon-root.MuiTablePagination-selectIcon {
  visibility: hidden;
}
.page-count {
  font-size: 15px;
  bottom: 15px;
  position: absolute;
  right: 100px;
}
@media screen and (max-width: 599px) {
  .MuiDataGrid-virtualScroller {
    overflow-x: scroll !important;
  }
}
  `
)

function NoRows() {
  return (
    <Typography sx={{ m: 4 }}>No lists to display.</Typography>
  )
};

function LoadErrorOverlay() {
  return (
    <LoadError height='300px'/>
  );
}

function TableWithActionButton({ useAutoRowHeight, customButtonAction, customButton, customButtonText, customActionButton, customButtonLink, isDataTableLoading, skeleton, rows, columns, rowHeight, tableHeight, title, initialState, message, hideTable, isError=false }) {

  function SkeletonRows() {
    return skeleton
  };

  const customToolbar = () => <CustomToolbar {...{title, customButtonAction, customButton, customButtonText, customActionButton, customButtonLink, isDataTableLoading, isError}}/>;

  const [pageSize, setPageSize] = React.useState(10);

  return (
    <GridWrapper container sx={{ pr: 0, pb: 0 }}>
      {hideTable ?
        <Grid item xs={12}>
          {customToolbar()}
          <Paper sx={{ mb: 0, mt: 1 }}>
            <Grid sx={{ height: 90 }}>
              <Typography sx={{ p: 3 }}>{message}</Typography>
            </Grid>
          </Paper>
        </Grid>
        :
        <>
          {useAutoRowHeight ?
            <div style={{ height: tableHeight, width: '160%' }}>
              <DataGrid
                slots={{
                  noRowsOverlay: isError ? LoadErrorOverlay : NoRows,
                  loadingOverlay: SkeletonRows,
                  toolbar: customToolbar
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 100]}
                rows={rows} columns={columns}
                initialState={initialState}
                getEstimatedRowHeight={() => 100}
                getRowHeight={() => 'auto'}
                pagination
                disableColumnMenu={true}
                loading={isDataTableLoading}
              />
            </div>
            :
            <div style={{ height: tableHeight, width: '160%' }}>
              <DataGrid 
                slots={{
                  noRowsOverlay: isError ? LoadErrorOverlay : NoRows,
                  loadingOverlay: SkeletonRows,
                  toolbar: customToolbar
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 100]}
                rows={rows} columns={columns}
                initialState={initialState}
                rowHeight={rowHeight}
                pagination
                disableColumnMenu={true}
                loading={isDataTableLoading}
              />
            </div>
          }
        </>
      }
    </GridWrapper>
  )
}

TableWithActionButton.propTypes = {
  customButtonAction: PropTypes.func,
  customButton: PropTypes.bool,
  customButtonText: PropTypes.string,
  customActionButton: PropTypes.bool,
  customButtonLink: PropTypes.string,
  isDataTableLoading: PropTypes.bool,
  isError: PropTypes.bool,
  skeleton: PropTypes.object,
  rows: PropTypes.array,
  columns: PropTypes.array,
  rowHeight: PropTypes.number,
  tableHeight: PropTypes.any,
  title: PropTypes.string,
  initialState: PropTypes.object,
  message: PropTypes.string,
  hideTable: PropTypes.bool,
  useAutoRowHeight: PropTypes.bool
}

export default TableWithActionButton;