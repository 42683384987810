import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

import { useQuery } from 'src/hooks';
import { exchangeForAccessToken } from 'src/utilities/okta';
import EvadataLoading from 'src/components/EvadataLoading';


export const OktaTokens = () => {
  const queryParameters = new URLSearchParams(window.location.href);
  const {oktaAuth} = useOktaAuth();
  const issuerOrigin = oktaAuth.getIssuerOrigin();  

  const authorizationCode = queryParameters.get(`${window.location.origin}/login/okta-callback?code`);
  const {isError, isLoading, data} = useQuery(['exchangeForAccessToken', authorizationCode], () => exchangeForAccessToken(oktaAuth, authorizationCode));

  if(data && !isLoading && !isError) {
    const {token_type, access_token, scope} = data;
    const {payload} = oktaAuth.token.decode(access_token);

    const storageProvider = oktaAuth.tokenManager.getStorage();

    const oktaApiToken = {
      apiAccessToken: access_token,
      authorizeUrl: `${issuerOrigin}/oauth2/v1/authorize`,
      claims: payload,
      expiresAt: payload.exp,
      scopes: [scope],
      tokenType: token_type
    };
    
    storageProvider.setItem('oktaApiToken', oktaApiToken);
  }

  if(isError) {
    return <Navigate to={'/500'}/>
  }

  return isLoading ? <EvadataLoading/> : <Navigate to={'/'}/>;
};
