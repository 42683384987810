import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

import EvadataLoading from 'src/components/EvadataLoading';
import {getOrgAccessToken, loadOrgWellKnown} from 'src/utilities/okta';
import {useQuery} from 'src/hooks';


export const OktaAuth = () => {
  const [loading, setLoading] = useState(false);
  const {oktaAuth} = useOktaAuth();

  const origin = window.location.origin;
  const {data: wellknown} = useQuery('orgWellKnow', () => loadOrgWellKnown(oktaAuth));

  useEffect(() => {
    const authenticate = async function() {
      const location = window.location.href.startsWith(`${origin}/login/`);

      if (location) {
        setLoading(false);
        return;
      }

      const token = getOrgAccessToken(oktaAuth);

      if (token) {
        setLoading(false);
        return;
      }

      setLoading(true);

      const codeVerifier = oktaAuth.pkce.generateVerifier();
      const codeChallenge = await oktaAuth.pkce.computeChallenge(codeVerifier);

      const transactionStorage = oktaAuth.storageManager.getTransactionStorage();
      transactionStorage.setItem('oktaOrgCodeVerifier', codeVerifier);

      const searchParams = new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID,
        response_type: 'code',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        scope: 'okta.groups.manage okta.users.manage okta.apps.manage okta.schemas.manage okta.profileMappings.manage okta.clients.manage okta.authorizationServers.manage',
        redirect_uri: `${origin}/login/okta-callback`,
        state: '1234'
      });

      if(wellknown) {
        const {authorization_endpoint} = wellknown;
        const _url = new URL(authorization_endpoint); // see: https://developer.okta.com/docs/concepts/auth-servers/#which-authorization-server-should-you-use
        _url.search = searchParams;
        window.location.replace(_url);
      }
    }

    authenticate();
  }, [oktaAuth, origin, wellknown]);

  return loading ? <EvadataLoading/> : <Outlet/>;
};
