import {useEffect, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {Outlet} from 'react-router-dom';

import EvadataLoading from 'src/components/EvadataLoading';
import Layout from 'src/components/Layout';


export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!authState) {
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if(!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
      setIsLoading(true);
    }
  }, [oktaAuth, authState]);

  return isLoading ? <EvadataLoading/> : <Layout><Outlet/></Layout>;
};
